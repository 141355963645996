import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { BASE_URL } from "../../../constant";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

const OtpVerify = ({ openModal, handleClose, userInput, registerUser }) => {
    const [ otp, setOtp ] = useState({ digit1: '', digit2: '', digit3: '', digit4: '' });
    const [ errorMsg, setErrorMsg ] = useState('');

    const handleOtpChange = (e, nextInputId) => {
        const { name, value } = e.target;
        if (value.match(/^[0-9]$/)) {
          setOtp({ ...otp, [name]: value });
    
          const nextInput = document.getElementById(nextInputId);
          if (nextInput && value) {
            nextInput.focus();
          }
        } else if (value === '') {
          setOtp({ ...otp, [name]: '' });
        }
    };
    
    const handleOtpSubmit = async () => {
        setErrorMsg('');
        const completeOtp = otp.digit1 + otp.digit2 + otp.digit3 + otp.digit4;
        if (completeOtp.length === 4) {
            try {
                const { primaryPhone } = userInput;
                const { data } = await axios.post(BASE_URL+'/verifyOtp', { primaryPhone: '+91 '+primaryPhone, otp: completeOtp });
                if(data?.message && data?.status === 1) {
                    registerUser(true);
                } else {
                    setErrorMsg('OTP does not match');
                }
            } catch(err) {
                registerUser(false);
            }
        } else {
            if(completeOtp.length !== 4){
                setErrorMsg('Invalid OTP');
                return false;
            }
        }
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openModal}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Verify
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                {
                    errorMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="error" onClose={() => setErrorMsg('')}>{errorMsg}</Alert>
                        </Stack>
                    )
                }
                <div className="row">
                    <div className="col-12">
                        <p>
                            We've sent a code to +91 <b> {userInput.primaryPhone} </b>
                        </p>
                    </div>
                    {[1, 2, 3, 4].map((digit) => (
                        <div className="col-3" key={digit}>
                            <div className="mb-3 input-group-lg">
                                <input
                                    maxLength="1"
                                    name={`digit${digit}`}
                                    type="text"
                                    className="form-control text-center"
                                    id={`digit-${digit}`}
                                    placeholder="x"
                                    value={otp[`digit${digit}`]}
                                    onChange={(e) => handleOtpChange(e, `digit-${digit + 1}`)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>
                            Didn’t get a code or facing any issue? Reach out to us on help@capitaltrends.in, we will sort it out for you.
                        </p>
                    </div>   
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleOtpSubmit}>
                    Submit
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}


export default OtpVerify;