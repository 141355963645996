import React from "react";
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: 400,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
}));

const ViewTradeStrategy = ({
    openTradeStrategyModal,
    setTradeStrategyModalOpen,
    tradeStrategy,
}) => {
    const filterTradeStrategy = tradeStrategy.filter(({ notes }) => notes.trim()!=='');
    const handleClose = () => setTradeStrategyModalOpen(false);

    return (
        <Dialog
            open={openTradeStrategyModal}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogContent>
                <DialogContentText component={'div'}>
                    {
                        filterTradeStrategy.length > 0 && filterTradeStrategy.map(({ notes, createdDate}, index) => (
                            <Item key={index} sx={{ my: 1, mx: 'auto', p: 2, textAlign: 'center' }}>
                                <Stack direction="row" sx={{ alignItems: 'center' }}>
                                    <Typography component={'span'} variant="caption" gutterBottom sx={{ display: 'block' }}>
                                        Updated on: {createdDate}
                                    </Typography>
                                    
                                </Stack>
                                <Typography component={'span'} noWrap style={{ 'whiteSpace' : 'initial' }}>{notes}</Typography>
                            </Item>
                        ))
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewTradeStrategy;