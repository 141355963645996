import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useAuth } from "../../../context/AuthProvider";
import { BASE_URL, MARKET_DASHBOARD_COMPONENTS, INDEXES_SYMBOL_COULMN } from '../../../constant/index';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: 400,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
}));

const Indexes = () => {
    const { token } = useAuth();
    const [ component, setSelectedComponents ] = useState('Nifty 50');
    const [ symbols, setSymbols ] = useState([]);
    const [ commaSeparatedSymbols, setCommaSeparatedSymbols ] = useState('');
    const [ openModal, setOpenModal ] = useState(false);
    const [ missingSymbols, setMissingSymbols ] = useState([]);
    const [ extraSymbols, setExtraSymbols ] = useState([]);
    const [ newSymbol, setNewSymbol ] = useState('');

    useEffect(() => {
        callAPIProcedure(component);
    }, []);

    const callAPIProcedure = async (sector) => {
        const timestamp = new Date().getTime(); // Current time in milliseconds
        const { data } = await axios.get(`${BASE_URL}/getSymbolsFromIndexes?sector=${sector}&_=${timestamp}`);
        const formattedData = data.map((item, index) => ({ item, id: index + 1 }));
        setSymbols(formattedData);
    };

    const onComponentSelection = async (value) => {
		setSelectedComponents(value);
        callAPIProcedure(value);
	}

    const columns = [
        { field: 'id', headerName: 'ID', width: 80 },
        { field: 'item', headerName: 'Stock Symbol', width: 170, editable: true },
    ]

    const compareSymbols = () => {
        const inputArray = commaSeparatedSymbols.split(',');
        const symbolsArray = symbols.map(({ item }) => item);
        const missingSymbols = inputArray.filter(symbol => !symbolsArray.includes(symbol));
        const extraSymbols = symbolsArray.filter(symbol => !inputArray.includes(symbol));
        setMissingSymbols(missingSymbols);
        setExtraSymbols(extraSymbols);
        setOpenModal(true);
    }

    const handleClose = () => setOpenModal(false);

    const resetList = () => setCommaSeparatedSymbols('');

    const addMissingSymbolToIndex = async () => {
        const commaSeparatedSymbolsArray = missingSymbols.join(', ');
        await axios.post(BASE_URL+'/addSymbolToIndex', { component, symbolList: commaSeparatedSymbolsArray }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        setOpenModal(false)
        setMissingSymbols([]);
    }

    const deleteMissingSymbolFromIndex = async () => {
        const commaSeparatedSymbolsArray = extraSymbols.join(', ');
        await axios.post(BASE_URL+'/deleteSymbolFromIndex', { component, symbolList: commaSeparatedSymbolsArray }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        setOpenModal(false);
        setExtraSymbols([]);
    }

    const addSymbolToMaster = async () => {
        await axios.post(BASE_URL+'/addSymbolToMaster', { symbol: newSymbol }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        setNewSymbol('');
    }

    return (
        <>
        <div className="container">
			<div className="row">
				<div className="col-3">
					<div className="md-links" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 0.6fr)', gap: '2px' }}>
						{MARKET_DASHBOARD_COMPONENTS.map(({ key, value, label }) => (
							<button 
								key={key} 
								onClick={() => onComponentSelection(value)} 
								className={`btn mdb-button-width ${value === component ? 'btn-primary button-cus' : 'btn-outline-secondary'}`}
							>
								{label}
							</button>
						))}
					</div>
				</div>

                <div className="col-4">
                    <div style={{ height: 650, width: '100%', marginTop: '10px' }}>
                        <DataGrid
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableColumnMenu
                            rowHeight={25}
                            rows={symbols || []}
                            editMode="row"
                            columns={columns}
                            pageSizeOptions={[5, 50, 100]}
                            localeText={{ noRowsLabel: "No Data found for selected date" }}
                            slots={{ toolbar: () => <GridToolbarQuickFilter /> }}
                            slotProps={{
                                toolbar: { component, setSymbols },
                            }}
                        />
                    </div>
                </div>
                
                <div className="col-5">
                    <div style={{ height: 650, width: '100%', marginTop: '10px' }}>
                        <TextField
                            id="filled-multiline-static"
                            label="Comma separated symbols (Example: A, B, C)"
                            multiline
                            rows={20}
                            onChange={(e) => setCommaSeparatedSymbols(e.target.value)}
                            value={commaSeparatedSymbols}
                            variant="filled"
                            fullWidth
                        />
                        <button onClick={compareSymbols} style={{ marginLeft: '10px', marginTop: '10px' }}> Compare </button>
                        <button onClick={resetList} style={{ marginLeft: '10px', marginTop: '10px' }}> Reset </button>
                    </div>    
                </div>
            </div>
        </div>
        <Dialog
            open={openModal}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogContent>
                <DialogContentText component={'div'}>
                    {
                        missingSymbols.length === 0 && extraSymbols.length === 0 ? (
                            <Item sx={{ my: 1, mx: 'auto', p: 2, textAlign: 'center' }}>
                                <Typography component={'span'} noWrap style={{ 'whiteSpace' : 'initial' }}>No change needed</Typography>
                            </Item>
                        ) : (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Missing Symbols: 
                                            <Typography
                                                    component={'span'}
                                                    variant="caption"
                                                    gutterBottom
                                                    sx={{ display: 'block' }}
                                                >
                                                    {missingSymbols.join(', ')}
                                            </Typography>
                                        </Typography>
                                        <button onClick={addMissingSymbolToIndex} style={{ marginLeft: '10px' }}> Add </button>
                                    </div>
                                    <div style={{ width: '50%' }}>    
                                        <Typography variant="h6" gutterBottom>
                                            Extra Symbols:
                                            <Typography
                                                    component={'span'}
                                                    variant="caption"
                                                    gutterBottom
                                                    sx={{ display: 'block' }}
                                                >
                                                    {extraSymbols.join(', ')}
                                            </Typography>
                                        </Typography>
                                        <button onClick={deleteMissingSymbolFromIndex} style={{ marginLeft: '10px' }}> Delete </button>
                                    </div>    
                                </div>
                                <div style={{ marginTop: '30px'}}>
                                    <Typography variant="h6" gutterBottom>
                                        Add symbol to Master Table:
                                        <div>
                                            <input type="text" placeholder="Enter the symbol" onChange={(e) => setNewSymbol(e.target.value)}/>
                                            <button onClick={addSymbolToMaster}> Add </button>
                                        </div>
                                    </Typography>
                                </div>
                            </>
                        )
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Ok</Button>
            </DialogActions>
        </Dialog> 
        </>       
    )
}

export default Indexes;