import React from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAuth } from "../../../context/AuthProvider";
import { ADMIN_USERS } from '../../../constant/index';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginTop: '30px',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
}));

const InactiveTrades = ({ inActiveTrades, columns, handleOpenStrategy, handleEvent, deleteTrades }) => {
    const { userEmail } = useAuth();

    return (
        <>  
            <div>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Item>
                        <Typography component={'span'} variant="h6" gutterBottom sx={{ display: 'block' }}>
                            Past Trades
                        </Typography>
                    </Item>
                    {
                        ADMIN_USERS.includes(userEmail) && (
                            <Item>
                                <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end", alignItems: "center"}}>
                                    <Button onClick={handleOpenStrategy} variant="outlined">Mark as open</Button>
                                    <Button onClick={deleteTrades} variant="outlined">Delete Trade</Button>
                                </Stack>
                            </Item>
                        )
                    }
                </Stack>
             </div>

            <Box sx={{ height: 400, width: '100%', marginTop:'10px' }}>
                <DataGrid
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    onRowClick={handleEvent}
                    rows={inActiveTrades}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    hideFooterSelectedRowCount={true}
                />
            </Box>
        </>
    )
}

export default InactiveTrades;