import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ADMIN_USERS } from '../../../constant/index';
import { useAuth } from "../../../context/AuthProvider";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
}));

const ActiveTrades = ({ 
    activeTrades, 
    columns, 
    handleEvent,
    handleAdd,
    handleAddStrategy,
    handleCompleteStrategy,
    deleteTrades,
}) => {
    const { userEmail } = useAuth();
      
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Item>
                        <Typography component={'span'} variant="h6" gutterBottom sx={{ display: 'block' }}>
                            Active Trades
                        </Typography>
                    </Item>
                    {
                        ADMIN_USERS.includes(userEmail) && (
                            <Item>
                                <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end", alignItems: "center"}}>
                                    <Button onClick={handleAdd} variant="outlined">Add Trade</Button>
                                    <Button onClick={handleAddStrategy} variant="outlined">Update Strategy</Button>
                                    <Button onClick={handleCompleteStrategy} variant="outlined">Mark as closed</Button>
                                    <Button onClick={deleteTrades} variant="outlined">Delete Trade</Button>
                                </Stack>
                            </Item>
                        )
                    }
                </Stack>
            </div>
            <Box sx={{ height: 400, width: '100%', marginTop:'10px', marginBottom: '10px' }}>
                <DataGrid
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    rows={activeTrades}
                    onRowClick={handleEvent}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    hideFooterSelectedRowCount={true}
                />
            </Box>
        </>
    )
}

export default ActiveTrades;