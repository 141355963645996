const PERFORMANCE_MAP = {
    '1-Day': 'oneDayPerformance',
    '1-Week': 'weeklyPerformance',
    '3-Month': 'qtrPerformance',
    '6-Month': 'biannualPerformance',
    '1-Year': 'ytdPerformance',
    '3-Year': 'threeYearPerformance',
    '5-Year': 'fiveYearPerformance',
    '7-Year': 'sevenYearPerformance',
    'YTD': 'annualPerformance'
};

export const getMdbData = (arrayStockDataMaster, arrayStockMarketData, component, day, chartCategory) => {
    const performanceKey = PERFORMANCE_MAP[day];
    if (!performanceKey) return { topLabels: [], topData: [] };

    // Extract symbols from the component and filter market data
    const symbolListFromSectorCode = arrayStockDataMaster
        .filter(master => master.hasOwnProperty(component))
        .flatMap(master => master[component])
        .flatMap(symbol => 
            arrayStockMarketData
                .filter(market => market.hasOwnProperty(symbol))
                .map(market => ({ [symbol]: market[symbol] }))
        );

    // Map symbols to their performance data
    const finalArr = symbolListFromSectorCode.map(symbols => {
        const symbol = Object.keys(symbols)[0];
        return {
            label: symbol,
            data: symbols[symbol][performanceKey]
        };
    });

    // Sort the array by performance data
    finalArr.sort((a, b) => b.data - a.data);

    // Determine top labels and data based on component and chart category
    let topLabels, topData;
    if (component === 'Sectoral Indices' || component === 'Thematic Indices') {
        topLabels = finalArr.map(item => item.label);
        topData = finalArr.map(item => Number(item.data));
    } else {
        if (chartCategory === 'top') {
            topLabels = finalArr.slice(0, 5).map(item => item.label);
            topData = finalArr.slice(0, 5).map(item => Number(item.data));
        } else {
            topLabels = finalArr.slice(-5).map(item => item.label); // Get last 5 elements
            topData = finalArr.slice(-5).map(item => Number(item.data));
        }
    }

    return { topLabels, topData };
};
