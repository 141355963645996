import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import axios from "axios";
import Alert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddRecommendations from './AddRecommendations';
import AddTradeStrategy from './AddTradeStrategy';
import ViewTradeStrategy from './ViewTradeStrategy';
import InactiveTrades from './InactiveTrades';
import ActiveTrades from './ActiveTrades';
import { sortTrades, getSortedNotes } from "../../../utils";
import { BASE_URL, ADMIN_USERS } from '../../../constant/index';
import { useAuth } from "../../../context/AuthProvider";

const TradeRecommendations = () => {
    const { token, userEmail } = useAuth();
    const [ activeTrades, setActiveTrades ] = useState([]);
    const [ inActiveTrades, setInActiveTrades ] = useState([]);
    const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
    const [ successMsg, setSuccessMsg ] = useState('');
    const [ openTradeStrategyModal, setTradeStrategyModalOpen ] = useState(false);
    const [ openAddRecommendationsModal, setAddRecommendationsModalOpen ] = useState(false);
    const [ openAddStrategyModal, setAddStrategyModalOpen ] = useState(false);
    const [ tradeStrategy, setTradeStrategy ] = useState([]);
    const [ selectedTradeId, setSelectedTradeId ] = useState('');
    const [ selectedInactiveTradeId, setSelectedInactiveTradeId ] = useState('');
    
    const getRecommendations = async () => {
        setLoading(true);
		try {
            const { data } = await axios.post(BASE_URL+'/getRecommendations', {}, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            if(data?.inactiveTrades && data?.activeTrades) {
                const inactiveTrades = sortTrades(data?.inactiveTrades, 'UPDATED_DATE');
                const activeTrades = sortTrades(data?.activeTrades);
                setActiveTrades(activeTrades || []);
                setInActiveTrades(inactiveTrades || []);
            }
            setLoading(false);
        } catch(err) {
            const errMsg = err?.response?.data?.msg;
            setErrorMsg(errMsg || 'Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
			setLoading(false);
		}  
    }

    useEffect(() => {
        getRecommendations();
    }, []);

    const viewAction = (selectedId) => {
        setTradeStrategyModalOpen(true);
        const selectedActiveRow = activeTrades.filter(item => item.id === selectedId);
        const selectedInActiveRow = inActiveTrades.filter(item => item.id === selectedId);

        if(selectedActiveRow.length > 0) {
            const sortedActiveNotes = getSortedNotes(selectedActiveRow);
            setTradeStrategy(sortedActiveNotes);
        } else {
            const sortedInActiveNotes = getSortedNotes(selectedInActiveRow);
            setTradeStrategy(sortedInActiveNotes);
        }
       
    }

    const handleAdd = () => {
        setAddRecommendationsModalOpen(true);
        setSelectedTradeId('');
    }

    const handleAddStrategy = () => {
        setErrorMsg("");
        if(selectedTradeId) {
            setAddStrategyModalOpen(true);
        } else {
            setErrorMsg("Please select any one from the below rows to update!")
        }
    }

    const handleActiveEvent = (event) => {
        setSelectedTradeId(event.row);
    }

    const handleInactiveEvent = (event) => {
        setSelectedInactiveTradeId(event.row);
    }

    const handleCompleteStrategy = async () => {
        setSuccessMsg('');
        setErrorMsg('');
        if(!selectedTradeId) {
            setErrorMsg("Please select any one from the below rows to update!");
            return;
        }
        try {
            setLoading(true);
            const { data } = await axios.post(BASE_URL+'/updateStrategyToComplete', { id: selectedTradeId.TRADE_ID }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if(data?.msg) {
                setSuccessMsg(data.msg);
                getRecommendations();
            }
        } catch(err) {
            setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
        } finally {
            setLoading(false);
            setSelectedTradeId('');
        }
    }

    const handleOpenStrategy = async () => {
        setSuccessMsg('');
        setErrorMsg('');
        if(!selectedInactiveTradeId) {
            setErrorMsg("Please select any one from the below rows to update!");
            return;
        }
        try {
            setLoading(true);
            const { data } = await axios.post(BASE_URL+'/updateStrategyToOpen', { id: selectedInactiveTradeId.TRADE_ID }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if(data?.msg) {
                setSuccessMsg(data.msg);
                getRecommendations();
            }
        } catch(err) {
            setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
        } finally {
            setLoading(false);
        }
    }

    const deleteInActiveTrades = async () => {
        setSuccessMsg('');
        setErrorMsg('');
        if(!selectedInactiveTradeId) {
            setErrorMsg("Please select any one from the below rows to update!");
            return;
        }
        try {
            setLoading(true);
            const { data } = await axios.post(BASE_URL+'/deleteTradeStrategy', { id: selectedInactiveTradeId.TRADE_ID }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if(data?.msg) {
                setSuccessMsg(data.msg);
                getRecommendations();
            }
        } catch(err) {
            setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
        } finally {
            setLoading(false);
            setSelectedTradeId('');
        }
    }

    const deleteActiveTrades = async () => {
        setSuccessMsg('');
        setErrorMsg('');
        if(!selectedTradeId) {
            setErrorMsg("Please select any one from the below rows to update!");
            return;
        }
        try {
            setLoading(true);
            const { data } = await axios.post(BASE_URL+'/deleteTradeStrategy', { id: selectedTradeId.TRADE_ID }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if(data?.msg) {
                setSuccessMsg(data.msg);
                getRecommendations();
            }
        } catch(err) {
            setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
        } finally {
            setLoading(false);
            setSelectedTradeId('');
        }
    }

    const ACTIVE_COLUMNS = [
        { 
            field: 'TRADE_TYPE', 
            headerAlign: 'left',
            headerName: 'Trade Type', 
            width: 100 
        },
        { 
            field: 'STOCK_SYMBOL', 
            headerAlign: 'left',
            headerName: 'Stock Symbol', 
            width: 150 
        },
        {
            field: 'FUTURES_LOT_SIZE',
            headerAlign: 'left',
            align: 'center',
            headerName: 'Futures Lot Size',
            width: 150,
        },
        {
          field: 'RECOMMENDED_DATE',
          headerAlign: 'center',
          align: 'center',
          headerName: 'Entry (IST)',
          valueFormatter: params => dayjs(params?.value).format("DD/MM/YYYY hh:mm A"),
          type: 'dateTime',
          width: 200,
        },
        {
          field: 'CURRENT_PRICE',
          headerAlign: 'center',
          align: 'center',
          headerName: 'Current Price',
          type: 'number',
          sortable: false,
          width: 120,
        },
        {
            field: 'OPENING_PRICE',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Entry Price',
            type: 'number',
            sortable: false,
            width: 110,
          },
          {
            field: 'CLOSING_PRICE',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Exit Price',
            type: 'number',
            sortable: false,
            width: 110,
          },
        {
            field: 'GAIN_LOSS_PER',
            headerName: 'Current Gain/Loss',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            width: 170,
            type: 'number',
        },
        {
            field: 'TRADE_STRATEGIES',
            headerName: 'Trade Strategy',
            width: 150,
            headerAlign: 'right',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer", paddingLeft: "100px" }}>
                        <IconButton aria-label="view" color="primary" onClick={() => viewAction(params.row.id)}>
                            <VisibilityIcon />
                        </IconButton>
                     </div>
                );
            }
        },
    ];

    const INACTIVE_COLUMNS = [
        { 
            field: 'TRADE_TYPE', 
            headerAlign: 'left',
            headerName: 'Trade Type', 
            width: 100 
        },
        { 
            field: 'STOCK_SYMBOL', 
            headerAlign: 'left',
            headerName: 'Stock Symbol', 
            width: 120 
        },
        {
            field: 'FUTURES_LOT_SIZE',
            headerAlign: 'left',
            align: 'center',
            headerName: 'Futures Lot Size',
            width: 140,
        },
        {
          field: 'RECOMMENDED_DATE',
          headerAlign: 'center',
          align: 'center',
          headerName: 'Entry (IST)',
          valueFormatter: params => dayjs(params?.value).format("DD/MM/YYYY hh:mm A"),
          type: 'dateTime',
          width: 200,
        },
        {
          field: 'UPDATED_DATE',
          headerAlign: 'center',
          align: 'center',
          headerName: 'Exit (IST)',
          valueFormatter: params => dayjs(params?.value).format("DD/MM/YYYY hh:mm A"),
          type: 'dateTime',
          width: 200,
        },
        {
            field: 'OPENING_PRICE',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Entry Price',
            type: 'number',
            sortable: false,
            width: 100,
          },
          {
            field: 'CLOSING_PRICE',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Exit Price',
            type: 'number',
            sortable: false,
            width: 100,
          },
        {
            field: 'GAIN_LOSS_PER',
            headerName: 'Gain/Loss',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            width: 160,
            type: 'number',
        },
        {
            field: 'TRADE_STRATEGIES',
            headerName: 'Trade Strategy',
            width: 140,
            headerAlign: 'right',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer", paddingLeft: "100px" }}>
                        <IconButton aria-label="view" color="primary" onClick={() => viewAction(params.row.id)}>
                            <VisibilityIcon />
                        </IconButton>
                     </div>
                );
            }
        },
    ];

    return (
        <> 
            {
                errorMsg && (
                    <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                        <Alert severity="error">{errorMsg}</Alert>
                    </Stack>
                )
            }

            {
                successMsg && (
                    <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                        <Alert severity="success">{successMsg}</Alert>
                    </Stack>
                )
            }
            
            <ActiveTrades 
                columns={ACTIVE_COLUMNS} 
                activeTrades={activeTrades} 
                handleEvent={handleActiveEvent}
                handleAdd={handleAdd}
                handleAddStrategy={handleAddStrategy}
                handleCompleteStrategy={handleCompleteStrategy}
                deleteTrades={deleteActiveTrades}
            />

            {
                inActiveTrades.length > 0 && (
                    <InactiveTrades 
                        columns={INACTIVE_COLUMNS} 
                        inActiveTrades={inActiveTrades}
                        handleOpenStrategy={handleOpenStrategy}
                        handleEvent={handleInactiveEvent}
                        deleteTrades={deleteInActiveTrades}
                    />
                )
            }
            
            <ViewTradeStrategy
                openTradeStrategyModal={openTradeStrategyModal}
                setTradeStrategyModalOpen={setTradeStrategyModalOpen}
                tradeStrategy={tradeStrategy}
            />
            
            {
                ADMIN_USERS.includes(userEmail) &&  (
                    <>
                        {
                            openAddRecommendationsModal && (
                                <AddRecommendations 
                                    openAddRecommendationsModal={openAddRecommendationsModal} 
                                    setAddRecommendationsModalOpen={setAddRecommendationsModalOpen}
                                    setSuccessMsg={setSuccessMsg}
                                    setErrorMsg={setErrorMsg}
                                    setLoading={setLoading}
                                    getRecommendations={getRecommendations}
                                    isLoading={isLoading}
                                />  
                            )
                        }        

                        {
                            selectedTradeId && (
                                <AddTradeStrategy 
                                    openAddStrategyModal={openAddStrategyModal}
                                    setAddStrategyModalOpen={setAddStrategyModalOpen}
                                    selectedTradeId={selectedTradeId}
                                    setSuccessMsg={setSuccessMsg}
                                    setErrorMsg={setErrorMsg}
                                    setLoading={setLoading}
                                    getRecommendations={getRecommendations}
                                />
                            )
                        }
                        
                    </>
                )
            }
        </>
    )
}

export default TradeRecommendations;