import React from "react";
import CircularProgress from '@mui/joy/CircularProgress';
import Alert from '@mui/material/Alert';
import { Card, Stack, Typography } from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart, markElementClasses, } from '@mui/x-charts/LineChart';
import { COLOR_BAR_CODE, ADMIN_USERS } from '../../../constant/index';
import { useAuth } from "../../../context/AuthProvider";

const RenderChart = ({ errorMsg, isLoading, topLabels, topData, day, component, lineChartLabels, lineChartData }) => {
  const { userEmail } = useAuth();
  if (errorMsg) {
    return (
      <Stack sx={{ width: '100%', marginTop: '10px' }} spacing={2}>
        <Alert severity="error">{errorMsg}</Alert>
      </Stack>
    )
	}
  
  if (isLoading) {
		return (
      <div className="loader-container">
        <CircularProgress size="lg" />
      </div>
		)
	}

  const barColors = topData.map((val, index) => COLOR_BAR_CODE[index % COLOR_BAR_CODE.length]);

  const formatLineChartData = (apiData) => {
    return apiData.map((item, index) => ({
      ...item,
      color: COLOR_BAR_CODE[index % COLOR_BAR_CODE.length],
    }));
  };

  return (
    <>
    <div className="chart-container">
        <Card sx={{ width: 900, height: 500 }}>
          <div style={{ paddingLeft: 40 }}>
            <Stack direction="row" justifyContent="center" spacing={3}>
              <Typography variant="h6">{`NSE: ${day} % performance of ${component} components.`}</Typography>
            </Stack>
            <BarChart
              width={750}
              height={430}
              series={[{ data: topData, barSize: 5 }]}
              layout="horizontal"
              margin={{ left: 145 }}
              barLabel={(item, context) => {
                const smallBarPadding = context.bar.width < 40 ? 27 : 0;
                const fontColor = context.bar.width < 40 ? 'black' : 'white';
                return (
                  <tspan 
                    dx={smallBarPadding}
                    fill={fontColor}
                    fontWeight="bold"
                    fontSize="12px"  
                  >
                    {item.value}
                  </tspan>
                );
              }}
              yAxis={[
                {
                  data: topLabels,
                  scaleType: "band",
                  barSize: 5,
                  colorMap: {
                    type: "ordinal",
                    values: topLabels,
                    colors: barColors,
                  },
                },
              ]}
            />
          </div>
        </Card>
    </div>
    {
      ADMIN_USERS.includes(userEmail) && (
        <div className="chart-container">
          <Card sx={{ width: 900, height: 500 }}>
            <div style={{ paddingLeft: 40 }}>
              <Stack direction="row" justifyContent="center" spacing={3}>
                <Typography variant="h6">{`NSE: ${day} close value of ${component} components.`}</Typography>
              </Stack>
              <LineChart
                width={750}
                height={430}
                margin={{ top: 110 }}
                series={formatLineChartData(lineChartData)}
                xAxis={[{ scaleType: 'point', data: lineChartLabels, marginLeft: 40 }]}
                sx={{
                  [`& .${markElementClasses.root}`]: {
                    stroke: '#8884d8',
                    scale: '0.1',
                    fill: '#fff',
                    strokeWidth: 2,
                  },
                }}
              />
            </div>
          </Card>
        </div>
      )
    }
    </>
  )
};

export default RenderChart;
